import { createAction, createReducer } from '@reduxjs/toolkit';
import { ButtonProps } from '../kit/components/Button';

export interface BreadcrumbState {
    button?: ButtonProps,
    pageTitle: string,
    path?: { link: string, title: string }[],
}

export const setButton = createAction<ButtonProps | undefined>('setButton');
export const setPageTitle = createAction<string>('setPageTitle');
export const setPath = createAction<{ link: string, title: string }[] | undefined>('setPath');

const actions = createReducer({
    button: undefined,
    pageTitle: 'Dashboard',
    path: undefined,
}, {
    [setButton.toString()]: (state, action: any) => ({
        ...state,
        button: action.payload,
    }),
    [setPageTitle.toString()]: (state, action: any) => ({
        ...state,
        pageTitle: action.payload,
    }),
    [setPath.toString()]: (state, action: any) => ({
        ...state,
        path: action.payload,
    }),
});

export default actions;
