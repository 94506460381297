import axios from 'axios';
import { getToken } from '../lib/auth';
import auth from './auth';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = {
    'x-token-name': 'web',
    'x-requested-with': 'XMLHttpRequest',
};

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 

    if (accessToken) {
        (config.headers as any).Authorization = `Bearer ${accessToken}`;
    }

    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('superToken');
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    requestReset: auth.requestReset,
    savePassword: auth.savePassword,
    saveTwoStep: auth.saveTwoStep,
    superLogin: auth.superLogin,
};

export default api;
