import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import Button from '../../components/Button';
import Modal from '../Modal';

interface ConfirmProps extends HTMLAttributes<HTMLDivElement> {
    btnLabelCancel?: string,
    btnLabelConfirm?: string,
    content?: string | JSX.Element,
    header?: string | JSX.Element,
    manual?: boolean,
    onCancel?: () => void,
    onConfirm?: () => void,
    trigger?: JSX.Element,
}

const Confirm: FC<ConfirmProps> = ({
    btnLabelCancel,
    btnLabelConfirm,
    children,
    content,
    header,
    manual,
    onCancel,
    onConfirm,
    trigger,
    ...props
}): JSX.Element => {
    const [ open, setOpen ] = useState(manual === undefined ? false : manual);

    useEffect(() => {
        if (manual !== undefined) {
            setOpen(manual);
        }
    }, [manual]);

    const handleCancel = (): void => {
        setOpen(false);
        if (onCancel) {
            onCancel();
        }
    }

    const handleConfirm = (): void => {
        setOpen(false);
        if (onConfirm) {
            onConfirm();
        }
    }

    return (<>
        {manual === undefined && trigger && React.cloneElement(trigger, {
            ...props,
            onClick: () => setOpen(true)
        })}
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        color="transparent"
                        label={btnLabelCancel || 'Annuleren'}
                        onClick={() => handleCancel()}
                    />
                    <Button
                        color="primary"
                        label={btnLabelConfirm || 'Ok'}
                        onClick={() => handleConfirm()}
                    />
                </div>
            )}
            header={header || 'Weet je het zeker?'}
            onClose={() => handleCancel()}
            open={open}
            size="small"
        >
            {children || content}
        </Modal>
    </>)
}

export default Confirm;
