import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface LoaderProps {
    fill?: boolean,
}

const Loader: FC<LoaderProps & HTMLAttributes<HTMLDivElement>> = ({
    className,
    fill,
    ...props
}): JSX.Element => {
    const el = (
        <div
            className={c([styles.circle, className])}
            {...props}
        >
            <span />
        </div>
    );

    return fill ? (
        <div className="loader">
            {el}
        </div>
    ) : el;
}

export default Loader;
