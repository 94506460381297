import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../../lib/util';
import styles from './styles.module.scss';

interface FormGroupProps extends HTMLAttributes<HTMLDivElement> {
    error?: boolean,
    inline?: boolean,
    label?: string,
    required?: boolean,
}

const FormGroup: FC<FormGroupProps> = ({ children, className, error, inline, label, required, ...props }): JSX.Element => {
    return (
        <div
            className={c([
                styles.group,
                error && styles.hasError,
                inline && styles.inline,
                className,
            ])}
            {...props}
        >
            {label && <label className={styles.label}>
                {label}
                {required && <span>*</span>}
            </label>}
            {children}
        </div>
    )
}

export default FormGroup;
