import { faAddressBook, faBoxes, faEnvelope, faFileContract, faFileInvoice, faHome, faLocationDot, faSackDollar, faTruckField, faUsers, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tooltip from '../../components/Tooltip';
import { hasRole, useProfile } from '../../lib/auth';
import { useTrans } from '../../lib/hooks';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

interface NavigationItem {
    active?: (p: string) => boolean,
    divider?: boolean,
    icon?: IconDefinition,
    to?: string,
    tooltip?: string,
    roles?: number[],
}

const items: NavigationItem[] = [{
    active: (p: string) => p === '/',
    icon: faHome,
    to: '/',
    tooltip: 'Dashboard',
    roles: [2, 3],
}, {
    active: (p: string) => p.includes('contracts'),
    icon: faFileContract,
    to: '/contracts',
    tooltip: 'Contracts',
    roles: [2, 3],
}, {
    active: (p: string) => p.includes('users'),
    icon: faUsers,
    to: '/users',
    tooltip: 'Users',
    roles: [1, 2],
}, {
    active: (p: string) => p.includes('locations'),
    icon: faLocationDot,
    to: '/locations',
    tooltip: 'Locations',
    roles: [2],
}, {
    active: (p: string) => p.includes('contacts'),
    icon: faAddressBook,
    to: '/contacts',
    tooltip: 'Contacts',
    roles: [2],
}, {
    active: (p: string) => p.includes('invoices'),
    icon: faFileInvoice,
    to: '/invoices',
    tooltip: 'Invoices',
    roles: [1, 2],
}, {
    active: (p: string) => p.includes('supplier-types'),
    icon: faBoxes,
    to: '/supplier-types',
    tooltip: 'Supplier types',
    roles: [1],
}, {
    active: (p: string) => p.includes('suppliers'),
    icon: faTruckField,
    to: '/suppliers',
    tooltip: 'Suppliers',
    roles: [1],
}, {
    active: (p: string) => p.includes('sponsors'),
    icon: faSackDollar,
    to: '/sponsors',
    tooltip: 'Sponsors',
    roles: [1],
}, {
    active: (p: string) => p.includes('templates'),
    icon: faEnvelope,
    to: '/templates',
    tooltip: 'Templates',
    roles: [1],
}]

const Drawer: FC = (): JSX.Element | null => {
    const { pathname } = useLocation();
    const profile = useProfile();
    const __ = useTrans('navigation');

    const checkPermission = (item: NavigationItem): boolean => {
        if (!profile || (item.roles && !hasRole(profile, item.roles))) {
            return false;
        }

        return true;
    }

    return profile ? (<>
        <div className={styles.base}>
            <div className={styles.top}>
                <img src="/favicon.png" alt="MyLucy" />
            </div>
            <div className={styles.navigation}>
                {items.filter(checkPermission).map((item: NavigationItem, index) => item.divider ? (
                    <div className={styles.divider} />
                ) : (
                    <Link
                        key={`nav-${index}`}
                        className={c([
                            item.active && item.active(pathname) && styles.isActive,
                        ])}
                        to={item.to || '/'}
                    >
                        {item.icon && (
                            <FontAwesomeIcon
                                color="#ffffff"
                                icon={item.icon}
                            />
                        )}
                        {item.tooltip && (
                            <Tooltip
                                content={__(item.tooltip)}
                                direction="left"
                            />
                        )}
                    </Link>
                ))}
            </div>
        </div>
    </>) : null;
}

export default Drawer;
