import React, { FC } from 'react';
import { ApiProfile } from '../../api/auth';
import { ApiUser, ApiUserSimple } from '../../models/User';
import styles from './styles.module.scss';

interface AvatarProps {
    className?: string,
    profile: ApiProfile | ApiUser | ApiUserSimple,
    size?: number,
}

const Avatar: FC<AvatarProps> = ({ className, profile, size }): JSX.Element => {
    return (
        <div
            className={`${styles.base}${className ? ` ${className}` : ''}`}
            style={size ? {
                height: size,
                width: size,
            } : {}}
        >
            {profile.avatar ? (
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${profile.avatar})` }}
                />
            ) : (<>
                {profile.firstName && profile.firstName.split('')[0].toUpperCase()}
                {profile.lastName && profile.lastName.split('')[0].toUpperCase()}
            </>)}
        </div>
    );
}

export default Avatar;
