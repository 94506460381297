import axios, { AxiosPromise } from 'axios';
import { getToken } from '../lib/auth';
import { ApiLocation } from '../models/Location';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProfile {
    id: string,
    avatar?: string,
    firstName: string,
    affix: string,
    lastName: string,
    name: string,
    company: string,
    emailAddress: string,
    roles: number[],
    locations: ApiLocation[],
    subscription: {
        id: string,
        type: number,
        users: number,
        locations: number,
        offers: boolean,
        isActive: boolean,
        isFree: boolean,
    }
}

const rest = {
    getProfile: (): Promise<ApiProfile> | Promise<any> => {
        if (getToken() === null) return Promise.resolve();
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string, password: string, secret: string): AxiosPromise<any> => {
        return axios.post('auth/login', {
            emailAddress,
            password,
            secret,
        }).then(async ({ data }: any) => {
            if (data.secretRequired) {
                return true;
            } else {
                localStorage.setItem('accessToken', data.accessToken);
                return data.profile;
            }
        });
    },
    logout: (force: boolean = false): void => {
        if (!force && localStorage.getItem('superToken')) {
            localStorage.removeItem('superToken');
            rest.getProfile();
            window.location.href = '/';
        } else {
            if (force) {
                localStorage.removeItem('superToken');
            }
            localStorage.removeItem('accessToken');
            store.dispatch(setProfile());
        }
    },
    requestReset: (emailAddress: string): AxiosPromise => {
        return axios.post(`auth/request-reset`, { emailAddress });
    },
    savePassword: (password: string, newPassword: string): AxiosPromise => {
        return axios.post(`auth/password`, { password, newPassword });
    },
    saveTwoStep: (state: boolean): AxiosPromise => {
        return axios.post(`auth/two-step`, { state });
    },
    superLogin: (userId: string): AxiosPromise<ApiProfile> => {
        return axios.post(`auth/super-login/${userId}`).then(({ data }: any) => {
            localStorage.setItem('superToken', data.accessToken);
            store.dispatch(setProfile(data.profile));
            window.location.href = '/';
            return data.profile;
        });
    },
}


export default rest;
