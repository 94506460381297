import React, { FC, HTMLAttributes } from 'react';
import { JustifyType } from '../../definitions';
import styles from './styles.module.scss';

interface RowProps {
    justify?: JustifyType,
}

const Row: FC<RowProps & HTMLAttributes<HTMLDivElement>> = ({
    justify,
    children,
    className,
    ...props
}): JSX.Element => {
    const classes: string[] = ['row', styles.row];

    if (justify) {
        classes.push(`justify-content-${justify}`);
    }

    if (className) {
        classes.push(className);
    }

    return (
        <div
            className={classes.join(' ')}
            {...props}
        >
            {children}
        </div>
    );
}

export default Row;
