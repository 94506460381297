import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import Input from '../../components/Input';
import { useDebounceSearch, useHandleKeyUp, useTrans } from '../../../lib/hooks';
import styles from './styles.module.scss';

interface TableActionsProps {
    autoLoad?: boolean,
    autoSearch?: boolean,
    children?: ReactNode,
    onSearch?: (query: string) => void,
    remember?: boolean,
}

const TableActions: FC<TableActionsProps> = ({
    autoLoad,
    autoSearch,
    children,
    onSearch,
    remember
}): JSX.Element => {
    const [ searchQuery, setSearchQuery ] = useState('');
    const debounceSearch = useDebounceSearch(onSearch);
    const __ = useTrans();
    
    const handleSearch = useCallback((value: string, update: boolean = false) => {
        localStorage.setItem(`apu-filter-${window.location.href}`, value);
        setSearchQuery(value);
        if (autoSearch || update) debounceSearch(value);
    }, [autoSearch, debounceSearch]);

    useHandleKeyUp(() => {
        if (searchQuery !== '') {
            handleSearch('', true);
        }
    }, ['Escape']);

    useHandleKeyUp(() => {
        debounceSearch(searchQuery);
    }, ['Enter']);

    useEffect(() => {
        const cache = localStorage.getItem(`apu-filter-${window.location.href}`);
        if (autoLoad) handleSearch(remember ? (cache || '') : '', true);
    }, [autoLoad, handleSearch, remember]);

    return (
        <div className={styles.actionsContainer}>
            {onSearch && (
                <div className={styles.searchContainer}>
                    <Input
                        icon={(
                            <FontAwesomeIcon
                                icon={searchQuery === '' ? faSearch : faTimes}
                                onClick={searchQuery === '' ? undefined : () => handleSearch('', true)}
                                style={searchQuery === '' ? undefined : { cursor: 'pointer' }}
                            />
                        )}
                        onChange={({ value }: any) => handleSearch(value)}
                        placeholder={__('Search')}
                        value={searchQuery}
                    />
                </div>
            )}
            {children && (
                <div className={styles.filterContainer}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default TableActions;
