import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../kit';
import { useBreadcrumb } from '../../../lib/hooks';
import styles from './styles.module.scss';

const Breadcrumb: FC = (): JSX.Element => {
    const [ pageTitle, path, button ] = useBreadcrumb();
    const navigate = useNavigate();

    return (
        <div className={styles.base}>
            <div className={styles.breadcrumb}>
                <div
                    className={styles.arrow}
                    onClick={() => navigate(-1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className={styles.page}>
                    <h3>{pageTitle}</h3>
                    {path.length > 0 && (
                        <div className={styles.path}>
                            {path.map((item, index) => (
                                <span key={`path-${index}`}>
                                    <Link to={item.link}>
                                        {item.title}
                                    </Link>
                                    {index !== path.length - 1 && ' - '}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {button && <Button {...button} />}
        </div>
    );
}

export default Breadcrumb;
