import React, { FC, HTMLAttributes } from 'react';

interface ColumnProps {
    col?: number,
    lg?: number,
    md?: number,
    sm?: number,
    xl?: number,
    xxl?: number,
}

const Column: FC<ColumnProps & HTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
    col,
    lg,
    md,
    sm,
    xl,
    xxl,
    ...props
}): JSX.Element => {
    const classes: string[] = [];
    const cols = { col, lg, md, sm, xl, xxl };

    Object.keys(cols).forEach((key, index) => {
        const n = Object.values(cols)[index];
        if (!n) return;
        classes.push(`col-${key === 'col' ? '' : `${key}-`}${n}`);
    });

    if (className) {
        classes.push(className);
    }

    return (
        <div
            className={classes.join(' ')}
            {...props}
        >
            {children}
        </div>
    );
}

export default Column;
