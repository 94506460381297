import React, { HTMLAttributes } from 'react';
import Dropdown from './Dropdown';
import Group from './Group';
import Input from './Input';
import Textarea from './Textarea';

class Form extends React.Component<HTMLAttributes<HTMLFormElement>> {
    static Dropdown = Dropdown;
    static Group = Group;
    static Input = Input;
    static Textarea = Textarea;

    render = (): JSX.Element => {
        const { children, ...rest } = this.props;

        return (
            <form {...rest}>
                {children}
            </form>
        );
    }
}

export default Form;
