import { faEuroSign } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, TextareaHTMLAttributes } from 'react';
import { c } from '../../../lib/util';
import Textarea, { TextareaProps } from '../../components/Textarea';
import styles from './styles.module.scss';

interface FormTextareaProps extends TextareaProps {
    info?: string,
    inline?: boolean,
    label?: string,
    money?: boolean,
}

const FormTextarea: FC<FormTextareaProps & TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
    info,
    inline,
    label,
    money,
    ...props
}): JSX.Element => {
    if (money) {
        props.icon = props.icon || faEuroSign;
        props.placeholder = '0.00';
    }

    return (
        <div
            className={c([
                styles.group,
                inline && styles.inline,
                money && styles.money,
                props.error && styles.hasError,
            ])}
        >
            {label && <label className={styles.label}>
                {label}
                {props.required && <span> *</span>}
            </label>}
            <div>
                <Textarea {...props} />
                {(typeof props.error === 'string' || info) && (
                    <p className={styles.info}>
                        {props.error || info}
                    </p>
                )}
            </div>
        </div>
    )
}

export default FormTextarea;
