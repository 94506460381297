import { FC, Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, RouteProps, Routes } from 'react-router-dom';
import api from './api';
import Loader from './components/Loader';
import NotificationCenter from './kit/modules/NotificationCenter';
import { getToken, hasRole, useProfile } from './lib/auth';
import { c } from './lib/util';
import Drawer from './modules/Drawer';
import TopBar from './modules/TopBar';
import styles from './styles.module.scss';

const ContactListView = lazy(() => import('./views/ContactView'));
const ContactEditView = lazy(() => import('./views/ContactView/Edit'));
const ContractListView = lazy(() => import('./views/ContractView'));
const ContractDetailView = lazy(() => import('./views/ContractView/Detail'));
const ContractEditView = lazy(() => import('./views/ContractView/Edit'));
const DashboardView = lazy(() => import('./views/DashboardView'));
const InvoiceListView = lazy(() => import('./views/InvoiceView'));
const LocationListView = lazy(() => import('./views/LocationView'));
const LocationEditView = lazy(() => import('./views/LocationView/Edit'));
const LoginView = lazy(() => import('./views/LoginView'));
const ResetPasswordView = lazy(() => import('./views/ResetPasswordView'));
const SponsorListView = lazy(() => import('./views/SponsorView'));
const SponsorEditView = lazy(() => import('./views/SponsorView/Edit'));
const SubscriptionView = lazy(() => import('./views/SubscriptionView'));
const SupplierListView = lazy(() => import('./views/SupplierView'));
const SupplierEditView = lazy(() => import('./views/SupplierView/Edit'));
const SupplierTypeListView = lazy(() => import('./views/SupplierTypeView'));
const SupplierTypeEditView = lazy(() => import('./views/SupplierTypeView/Edit'));
const TemplateListView = lazy(() => import('./views/TemplateView'));
const TemplateEditView = lazy(() => import('./views/TemplateView/Edit'));
const UserListView = lazy(() => import('./views/UserView'));
const UserEditView = lazy(() => import('./views/UserView/Edit'));

const PageLayout: FC<RouteProps & { fullWidth?: boolean }> = ({ fullWidth }): JSX.Element => {
    const profile = useProfile();

    return (<>
        <TopBar />
        <Drawer />
        <main className={c([styles.main, !profile && styles.notAuthenticated])}>
            <div className={`container${fullWidth ? '-fluid' : ''}`}>
                <Suspense fallback={<Loader fill />}>
                    <Outlet />
                </Suspense>
            </div>
        </main>
    </>);
}

const ProtectedRoute: FC<RouteProps & { roles?: number[] }> = ({ element, roles }): any => {
    const profile = useProfile();

    if (!profile || (roles && !hasRole(profile, roles))) {
        return <Navigate to="/login" replace />
    }

    return element;
}

const App: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const profile = useProfile();

    useEffect(() => {
        if (getToken()) {
            api.getProfile().finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, []);

    return isLoading ? (
        <Loader fill />
    ) : (
        <BrowserRouter>
            <Routes>
                <Route element={<PageLayout />}>
                    {profile && (hasRole(profile, [1]) || profile.subscription) && (<>
                        <Route
                            element={<ProtectedRoute element={<DashboardView />} roles={[1, 2, 3, 4]} />}
                            index
                        />

                        {/* account */}
                        <Route
                            element={<ProtectedRoute element={<UserEditView type="account" />} roles={[1, 2, 3, 4]} />}
                            path="/account"
                        />
                        <Route
                            element={<ProtectedRoute element={<SubscriptionView />} roles={[2, 3, 4]} />}
                            path="/account/subscription"
                        />

                        {/* contacts */}
                        <Route
                            element={<ProtectedRoute element={<ContactListView />} roles={[1, 2]} />}
                            path="/contacts"
                        />
                        <Route
                            element={<ProtectedRoute element={<ContactEditView />} roles={[1, 2]} />}
                            path="/contacts/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<ContactEditView />} roles={[1, 2]} />}
                            path="/contacts/:id/edit"
                        />

                        {/* contracts */}
                        <Route
                            element={<ProtectedRoute element={<ContractListView />} roles={[1, 2, 3]} />}
                            path="/contracts"
                        />
                        <Route
                            element={<ProtectedRoute element={<ContractEditView />} roles={[1, 2, 3]} />}
                            path="/contracts/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<ContractDetailView />} roles={[1, 2, 3]} />}
                            path="/contracts/:id"
                        />
                        <Route
                            element={<ProtectedRoute element={<ContractEditView />} roles={[1, 2, 3]} />}
                            path="/contracts/:id/edit"
                        />

                        {/* invoices */}
                        <Route
                            element={<ProtectedRoute element={<InvoiceListView />} roles={[1, 2]} />}
                            path="/invoices"
                        />

                        {/* locations */}
                        <Route
                            element={<ProtectedRoute element={<LocationListView />} roles={[1, 2]} />}
                            path="/locations"
                        />
                        <Route
                            element={<ProtectedRoute element={<LocationEditView />} roles={[1, 2]} />}
                            path="/locations/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<LocationEditView />} roles={[1, 2]} />}
                            path="/locations/:id/edit"
                        />

                        {/* sponsors */}
                        <Route
                            element={<ProtectedRoute element={<SponsorListView />} roles={[1]} />}
                            path="/sponsors"
                        />
                        <Route
                            element={<ProtectedRoute element={<SponsorEditView />} roles={[1]} />}
                            path="/sponsors/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<SponsorEditView  />} roles={[1]} />}
                            path="/sponsors/:id/edit"
                        />

                        {/* suppliers */}
                        <Route
                            element={<ProtectedRoute element={<SupplierListView />} roles={[1]} />}
                            path="/suppliers"
                        />
                        <Route
                            element={<ProtectedRoute element={<SupplierEditView />} roles={[1]} />}
                            path="/suppliers/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<SupplierEditView  />} roles={[1]} />}
                            path="/suppliers/:id/edit"
                        />

                        {/* type */}
                        <Route
                            element={<ProtectedRoute element={<SupplierTypeListView />} roles={[1]} />}
                            path="/supplier-types"
                        />
                        <Route
                            element={<ProtectedRoute element={<SupplierTypeEditView />} roles={[1]} />}
                            path="/supplier-types/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<SupplierTypeEditView  />} roles={[1]} />}
                            path="/supplier-types/:id/edit"
                        />

                        {/* templates */}
                        <Route
                            element={<ProtectedRoute element={<TemplateListView />} roles={[1]} />}
                            path="/templates"
                        />
                        <Route
                            element={<ProtectedRoute element={<TemplateEditView />} roles={[1]} />}
                            path="/templates/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<TemplateEditView />} roles={[1]} />}
                            path="/templates/:id/edit"
                        />

                        {/* users */}
                        <Route
                            element={<ProtectedRoute element={<UserListView />} roles={[1, 2]} />}
                            path="/users"
                        />
                        <Route
                            element={<ProtectedRoute element={<UserEditView type="user" />} roles={[1, 2]} />}
                            path="/users/create"
                        />
                        <Route
                            element={<ProtectedRoute element={<UserEditView type="user" />} roles={[1, 2]} />}
                            path="/users/:id/edit"
                        />
                    </>)}
                    {profile && hasRole(profile, [2, 3]) && !profile.subscription ? (
                        <Route
                            element={<ProtectedRoute element={<SubscriptionView />} roles={[2, 3]} />}
                            path="*"
                        />
                    ) : (<>
                        <Route path="/auth/reset-password" element={<ResetPasswordView />} />
                        <Route path="*" element={<LoginView />} />
                    </>)}
                </Route>
            </Routes>
            <NotificationCenter />
        </BrowserRouter>
    );
}

export default App;
