import React, { createRef, FC, RefObject, useRef, useState } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import Button from '../../components/Button';
import Segment from '../../components/Segment';
import styles from './styles.module.scss';
import { useOnClickOutside } from '../../../lib/hooks';

interface Position {
    left?: number | string,
    top?: number | string,
    right?: number | string,
    bottom?: number | string,
}

interface DropdownProps {
    children?: React.ReactNode,
    icon?: IconDefinition,
}

const Dropdown: FC<DropdownProps> = ({ children, icon }): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ position, setPosition ] = useState<Position>({});
    const ref: RefObject<HTMLDivElement> = createRef();
    useOnClickOutside(ref, () => setIsOpen(false));

    const toggleDropdown = () => {
        if (containerRef.current) {
            const pos: Position = {};
            const rect = containerRef.current.getBoundingClientRect();

            if (rect.x + rect.width + 16 > window.innerWidth) {
                pos.left = 'auto';
                pos.right = 0;
            }
            if (rect.x < 16) {
                pos.left = 0;
                pos.right = 'auto';
            }
            if (rect.y + rect.height + 16 > window.innerHeight) {
                pos.top = 'auto';
                pos.bottom = 16;
            }
            if (rect.x < 16) {
                pos.top = 16;
                pos.bottom = 'auto';
            }

            setPosition(pos);
        }

        setIsOpen(!isOpen);
    }

    return (
        <div
            ref={ref}
            onClick={(e) => e.stopPropagation()}
            style={{ position: 'relative' }} 
        >
            <Button
                icon={icon || faEllipsisV}
                onClick={() => toggleDropdown()}
                color="transparent"
            />
            <div
                className={[
                    styles.dropdownContainer,
                    isOpen && styles.dropdownContainerIsOpen,
                ].join(' ')}
                ref={containerRef}
                style={position}
            >
                <Segment style={{ padding: 0 }}>
                    {children}
                </Segment>
            </div>
        </div>
    );
}

export default Dropdown;
