import { isString } from 'lodash';
import React, { FC, HTMLAttributes } from 'react';
import Loader from '../../../components/Loader';
import { c } from '../../../lib/util';
import styles from './styles.module.scss';

interface SegmentProps extends HTMLAttributes<HTMLDivElement> {
    className?: string,
    header?: string | JSX.Element,
    headerButton?: JSX.Element,
    isLoading?: boolean,
}

const Segment: FC<SegmentProps> = ({
    children,
    className,
    header,
    headerButton,
    isLoading,
    ...props
}): JSX.Element => {
    return (
        <div
            className={c([
                styles.base,
                isLoading && styles.isLoading,
                className,
            ])}
            {...props}
        >
            {isLoading && <Loader className={styles.loader} />}
            {header && (
                <div className={styles.header}>
                    {isString(header) ? <h2>{header}</h2> : header}
                    {headerButton}
                </div>
            )}
            {children}
        </div>
    );
}

export default Segment;
