import origDayJs from 'dayjs';
import wNumb from 'wnumb';
require('dayjs/locale/nl');

export const c = (classes: any[]): string => {
    return classes.filter((o) => o !== '' && o !== false && o !== undefined).join(' ');
}

export const dayjs = (date?: origDayJs.ConfigType): origDayJs.Dayjs => {
    return origDayJs(date).locale('nl');
}

export const money = (
    str: any,
    decimals: number = 2,
    mark: string = ',', 
    thousand: string = '.',
    prefix: string = '€ '
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
        prefix,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};

export const number = (
    str: any,
    decimals: number = 0,
    mark: string = ',', 
    thousand: string = '.'
): string => {
    const format = wNumb({
        decimals,
        thousand,
        mark,
    });

    return format.to(decimals === 0 ? parseInt(str) : parseFloat(str));
};
