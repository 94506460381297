import { faSignOut } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import Avatar from '../../components/Avatar';
import { useProfile } from '../../lib/auth';
import Breadcrumb from './Breadcrumb';
import styles from './styles.module.scss';

const TopBar: FC = (): JSX.Element | null => {
    const profile = useProfile();

    return profile ? (<>
        <div className={styles.base}>
            <div>
                <Breadcrumb />
            </div>
            <div className={styles.profile}>
                <div className={styles.meta}>
                    <span>{profile.name}</span>
                    <Link
                        className={styles.profile}
                        to="/account"
                    >
                        Instellingen
                    </Link>
                </div>
                <Avatar profile={profile} size={36} />
                <div className={styles.logout} onClick={() => api.logout()}>
                    <FontAwesomeIcon icon={faSignOut} />
                </div>
            </div>
        </div>
        <div className={styles.push} />
    </>) : null;
}

export default TopBar;
