import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import nl from './translations/nl.json';
import './kit/index.scss';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'nl',
    resources: {
        nl: { common: nl },
    }
});

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </Provider>
);
