import React, { HTMLAttributes } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import MenuDivider from './Divider';
import Dropdown from './Dropdown';
import MenuHeader from './Header';
import MenuItem from './Item';
import styles from './styles.module.scss';

interface MenuProps extends HTMLAttributes<HTMLDivElement | HTMLFormElement | HTMLUListElement> {
    as?: 'div' | 'form' | 'ul',
    dropdown?: boolean,
    icon?: IconDefinition,
    tabs?: boolean,
}

class Menu extends React.Component<MenuProps> {
    static Divider = MenuDivider;
    static Header = MenuHeader;
    static Item = MenuItem;

    render = (): JSX.Element => {
        const { as, children, className, dropdown, icon, tabs, ...rest } = this.props;
        const Elem = as || 'div';

        const base = (
            <Elem
                className={[
                    styles.base,
                    dropdown ? styles.dropdown : '',
                    tabs ? styles.tabs : '',
                    className,
                ].join(' ')}
                {...rest}
            >
                {children}
            </Elem>
        );

        if (dropdown) {
            return (
                <Dropdown icon={icon}>
                    {base}
                </Dropdown>
            );
        }

        return base;
    }
}

export default Menu;
