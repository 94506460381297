import debounce from 'lodash/debounce';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../api';
import { DropdownOption } from '../kit';
import { ButtonProps } from '../kit/components/Button';
import { setButton, setPageTitle, setPath } from '../reducers/breadcrumb';
import { AppState } from '../store';

export const useBreadcrumb = (): [
    string,
    { link: string, title: string }[],
    ButtonProps | undefined,
] => {
    const { pageTitle, path, button } = useSelector((state: AppState) => state.breadcrumb);
    return [ pageTitle || '', path || [], button ];
}

export const useDebounceSearch = (callback?: (query: string) => void, delay: number = 300) => {
    const [ debounceSearch ] = useState(() => debounce(callback ? callback : () => {}, delay));
    return debounceSearch;
};

export const useDropdownOptions = (
    obj: string,
    permissions: () => boolean = () => true,
    append: DropdownOption[] = [],
): DropdownOption[] => {
    const [ options, setOptions ] = useState<DropdownOption[]>([]);
    const [ cont, setCont ] = useState<boolean>(false);

    useEffect(() => {
        const go = permissions();
        // if (!go) setOptions([]);
        setCont(go);
    }, [permissions]);

    useEffect(() => {
        setOptions([]);
    }, [obj]);

    const fetch = useCallback(() => {
        request.get(`${obj}${obj.includes('?') ? '&' : '?'}limit=9999`).then(({ data }) => {
            setOptions([ 
                ...data.data.map((o: any) => ({
                    text: o.name,
                    value: o.id,
                    extra: o,
                })),
                ...append
            ]);
        });
    }, [obj]);// eslint-disable-line

    useEffect(() => {
        if (!cont) return;
        fetch();
    }, [cont, fetch]);

    return options;
}

export const useHandleKeyUp = (callback: (key: string, e: Event) => void, keys: string[]) => {
    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if (keys.includes(e.key)) {
                callback(e.key, e);
            }
        }

        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
    });
}

export const useInterval = (callback: any, delay: number | null): void => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = (): void => {
            const cb: any = callbackRef.current;
            cb();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const useSetBreadcrumb = (
    title: string = '',
    crumb?: { link: string, title: string }[],
    button?: ButtonProps,
): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (title === '') return;
        dispatch(setPageTitle(title));
    }, [dispatch, title]);

    useEffect(() => {
        dispatch(setPath(crumb));
    }, [dispatch, crumb]);

    useEffect(() => {
        dispatch(setButton(button));
    }, [dispatch, button]);
}

export const useTimeout = (callback: any, deps: any[] = [], delay: number = 300) => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = setTimeout((): void => {
            const cb: any = callbackRef.current;
            cb();
        }, delay);
        return () => clearInterval(id);
    }, [ callback, ...deps] ); // eslint-disable-line
}

export const useTrans = (section?: string): (
    key: string,
    section?: string,
    replacement?: any[],
) => string => {
    const { t } = useTranslation('common');

    return (
        key: string,
        s?: string,
        replacement?: any[],
    ): string => {
        let str = '';

        if (s || s === '') {
            str = t(`${s ? s + '.' : 'general.'}${key}`);
        } else {
            str = t(`${section ? section + '.' : 'general.'}${key}`); 
        }

        if (replacement) {
            replacement.forEach((o, i) => {
                const reg = new RegExp(`\\$${i}`, 'g');
                str = str.replace(reg, o);
            })
        }

        return str;
    };
}

export const useWindowScroll = (callback: (e: Event) => void, delay: number = 300, deps: any[] = []) => {
    useEffect(() => {
        let scrollTimer: NodeJS.Timeout; 

        const handleScroll = (e: Event) => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export const useOnClickOutside = (ref: RefObject<any>, handler: (e: any) => void) => {
    useEffect(() => {
        const listener = (e: any) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }
            handler(e);
        };

        document.addEventListener('mousedown', listener, true);
        document.addEventListener('touchstart', listener, true);
        return () => {
            document.removeEventListener('mousedown', listener, true);
            document.removeEventListener('touchstart', listener, true);
        };
    }, [ref, handler]);
}
