import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheckCircle, faExclamationTriangle, faExclamationCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, HTMLAttributes, useEffect } from 'react';
import Button from '../Button';
import styles from './styles.module.scss';
import { c } from '../../../lib/util';

export type MessageType = 'error' | 'success' | 'warning';

export interface MessageProps extends HTMLAttributes<HTMLDivElement> {
    autoHide?: boolean | number,
    content?: string,
    fixed?: boolean,
    icon?: IconDefinition,
    onClose?: () => void,
    title?: string,
    type: MessageType,
}

const Message: FC<MessageProps> = ({
    autoHide,
    children,
    className,
    content,
    fixed,
    icon,
    onClose,
    title,
    type,
}) => {
    let customIcon = undefined;

    if (icon) {
        customIcon = icon;
    } else {
        if (type === 'error') customIcon = faExclamationCircle;
        else if (type === 'warning') customIcon = faExclamationTriangle;
        else if (type === 'success') customIcon = faCheckCircle;
    }

    useEffect(() => {
        if (autoHide && onClose) {
            setTimeout(() => onClose(), autoHide === true ? 2500 : autoHide);
        }
    }, [autoHide, onClose]);

    return (
        <div
            className={c([
                styles.base,
                styles[type],
                fixed && styles.fixed,
                className,
            ])}
        >
            {customIcon && <FontAwesomeIcon icon={customIcon} />}
            <div>
                {title ? <h6>{title}</h6> : null}
                {content ? <p>{content}</p> : children}
            </div>
            {onClose && (
                <Button
                    color="transparent"
                    icon={faTimes}
                    onClick={() => onClose()}
                />
            )}
        </div>
    );
}

export default Message;
