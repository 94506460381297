import React, { FC, HTMLAttributes, useState } from 'react';
import { useTrans } from '../../../lib/hooks';
import styles from './styles.module.scss';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
    error?: boolean,
    noResults?: string | boolean,
}

const TableRow: FC<TableRowProps> = ({ children, error, noResults }): JSX.Element => {
    const [ expand, setExpand ] = useState<boolean>(false);
    const __ = useTrans();

    const handleExpand = (): void => {
        setExpand((o) => !o);
    }

    return (
        <tr className={`${expand ? styles.expanded : ''} ${error ? styles.error : ''}`}>
            {noResults ? (
                <td colSpan={100}>
                    <em>{noResults === true ? __('No results') : noResults}</em>
                </td>
            ) : children}
        </tr>
    );
}

export default TableRow;
