import { configureStore } from '@reduxjs/toolkit';
import auth, { AuthState } from './reducers/auth';
import breadcrumb, { BreadcrumbState } from './reducers/breadcrumb';

export interface AppState {
    auth: AuthState,
    breadcrumb: BreadcrumbState,
}

export default configureStore({
    reducer: {
        auth,
        breadcrumb,
    },
})
