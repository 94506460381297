import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import React, { ChangeEvent, FC, InputHTMLAttributes, isValidElement, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { c } from '../../../lib/util';

export interface TextareaChangeProps {
    name: string,
    value?: string | boolean,
}

export interface TextareaProps {
    autoGrow?: boolean,
    error?: boolean | string,
    icon?: IconDefinition | JSX.Element,
    onChange?: (data: any, e: ChangeEvent<HTMLTextAreaElement>) => void,
    rows?: number,
}

const Textarea: FC<TextareaProps & InputHTMLAttributes<HTMLTextAreaElement>> = ({
    autoGrow,
    className,
    error,
    icon,
    onChange,
    value,
    ...props
}): JSX.Element => {
    const areaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();
    const [ focus, setFocus ] = useState<boolean>(false);
    
    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
            onChange({
                name: e.currentTarget.name,
                value: e.currentTarget.value,
            }, e);
        }
    }

    useEffect(() => {
        if (!autoGrow || !areaRef.current) return;
        areaRef.current.style.height = '5px';
        areaRef.current.style.height = `${areaRef.current.scrollHeight}px`;
    }, [autoGrow, value]); // eslint-disable-line

    return (
        <div
            className={c([
                styles.base,
                autoGrow && styles.autoGrow,
                !error && focus && styles.focus,
                error && styles.hasError,
                (error || icon) && styles.hasIcon,
                className,
            ])}
        >
            {(error || icon) && (
                <div className={styles.icon}>
                    {error ? (
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    ) : (
                        isValidElement(icon)
                            ? icon
                            : <FontAwesomeIcon icon={icon as IconDefinition} />
                    )}
                </div>
            )}
            <textarea
                ref={areaRef}
                onBlur={(e) => {
                    setFocus(false);
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    setFocus(true);
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                onChange={handleChange}
                value={value === undefined ? '' : value}
                {...props}
            />
        </div>
    )
}

export default Textarea;
