import { createAction, createReducer } from '@reduxjs/toolkit';
import { ApiProfile } from '../api/auth';

export interface AuthState {
    profile?: ApiProfile,
}

export const setProfile = createAction<ApiProfile|undefined>('setProfile');

const actions = createReducer({
    profile: undefined,
}, {
    [setProfile.toString()]: (state, action: any) => ({
        ...state,
        profile: action.payload,
    }),
});

export default actions;
