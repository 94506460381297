import { stringify } from 'qs';
import { useSelector } from 'react-redux';
import { ApiProfile } from '../api/auth';
import { AppState } from '../store';

export const apiUrl = (url: string, params?: { [key: string]: any } ): string => {    
    return `${process.env.REACT_APP_API_BASE_URL}${url}?_token=${getToken()}${params ? `&${stringify(params)}` : '' }`;
}

export const getToken = (): string | null => {
    if (window.self !== window.top) {
        return null;
    }

    if (localStorage.getItem('superToken')) {
        return localStorage.getItem('superToken');
    }
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

export const hasRole = (profile?: ApiProfile, roles: number[] = []): boolean => {
    if (!profile || !profile.roles) return false;
    let ret = false;

    roles.forEach((roleId: number) => {
        if (profile.roles.includes(roleId)) {
            ret = true;
        }
    });

    return ret;
}

export const useProfile = (): ApiProfile | undefined => {
    return useSelector((state: AppState) => state.auth.profile);
}
