import { faEuroSign } from '@fortawesome/pro-regular-svg-icons';
import React, { FC, InputHTMLAttributes } from 'react';
import { c } from '../../../lib/util';
import Input, { InputProps } from '../../components/Input';
import styles from './styles.module.scss';

interface FormInputProps extends InputProps {
    info?: string,
    inline?: boolean,
    label?: string,
    money?: boolean,
}

const FormInput: FC<FormInputProps & InputHTMLAttributes<HTMLInputElement>> = ({
    info,
    inline,
    label,
    money,
    ...props
}): JSX.Element => {
    if (money) {
        props.icon = props.icon || faEuroSign;
        props.placeholder = '0.00';
    }

    return (
        <div
            className={c([
                styles.group,
                inline && styles.inline,
                money && styles.money,
                props.error && styles.hasError,
            ])}
        >
            {label && <label className={styles.label}>
                {label}
                {props.required && <span> *</span>}
            </label>}
            <div>
                <Input {...props} />
                {(typeof props.error === 'string' || info) && (
                    <p className={styles.info}>
                        {props.error || info}
                    </p>
                )}
            </div>
        </div>
    )
}

export default FormInput;
