import React, { FC } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

type DirectionType = 'bottom' | 'left' | 'top' | 'right';

interface TooltipProps {
    children?: React.ReactNode,
    content?: string,
    direction: DirectionType,
}

const Tooltip: FC<TooltipProps> = ({ content, direction, children }): JSX.Element => {
    return (
        <div
            className={c([
                'tooltip',
                styles.tooltip,
                styles[direction],
            ])}
        >
            {content || children}
        </div>
    );
}

export default Tooltip;
